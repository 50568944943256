import URL from '@/api/urlConfig';
import http, { fetchApi } from '@/utils/axios';

/******************** 推荐内容管理 ********************/
/**
 * 查询推荐内容列表
 * @param {String} params.projectId 项目id
 * @param {Number} params.stateType 状态类型(1：正常，2：异常)
 * @param {Number} params.contentType 内容类型(1:文章，2:线上商品)
 * @param {String} params.commonId 帖子或商品id
 * @param {Number} params.page 页数
 * @param {Number} params.size 条数
 * @returns
 */
export const findRecommendContentList = (params) => fetchApi(URL.FIND_RECOMMEND_CONTENT_LIST, params, 'get', 'params', true);

/**
 * 添加推荐内容
 * @param {String} data.projectId 项目id
 * @param {Number} data.contentType 内容类型(1:文章，2:线上商品)
 * @param {String} data.commonId 帖子或商品id
 * @param {Number} data.priority 优先级
 * @param {Number} data.des 描述
 * @returns
 */
export const addRecommendContent = (data) => fetchApi(URL.ADD_RECOMMEND_CONTENT, data, 'post', 'data');

/**
 * 查询推荐内容详情
 * @param {String} id 内容id
 * @returns
 */
export const findRecommendContentDetail = (id) => fetchApi(`${URL.FIND_RECOMMEND_CONTENT_DETAIL}/${id}`, {});

/**
 * 编辑推荐内容
 * @param {String} data.id 推荐内容id
 * @param {Number} data.contentType 内容类型(1:文章，2:线上商品)
 * @param {String} data.commonId 帖子或商品id
 * @param {Number} data.priority 优先级
 * @param {Number} data.des 描述
 * @returns
 */
export const editRecommendContent = (data) => fetchApi(URL.EDIT_RECOMMEND_CONTENT, data, 'post', 'data');

/**
 * 删除推荐内容
 * @param {String} id 内容id
 * @returns
 */
export const removeRecommendContent = (id) => fetchApi(`${URL.REMOVE_RECOMMEND_CONTENT}/${id}`, {}, 'delete');

/**
 * 导出推荐内容
 * @param {String} params.projectId 项目id
 * @param {Number} params.stateType 状态类型(1：正常，2：异常)
 * @param {Number} params.contentType 内容类型(1:文章，2:线上商品)
 * @param {String} params.commonId 帖子或商品id
 * @param {Number} params.page 页数
 * @param {Number} params.size 条数
 * @returns
 */
export const exportRecommendContent = (params) =>
  http({
    url: URL.EXPORT_RECOMMEND_CONTENT,
    responseType: 'blob',
    timeout: 120000,
    method: 'get',
    params,
  });

/******************** 榜单数据管理 ********************/
/**
 * 查询榜单数据列表
 * @param {String} params.projectId 项目id
 * @param {String} params.updateTime 更新时间
 * @param {Number} params.page 页数
 * @param {Number} params.size 条数
 * @returns
 */
export const findRankingList = (params) => fetchApi(URL.FIND_RANKING_LIST, params, 'get', 'params', true);

/**
 * 导出榜单数据
 * @param {String} params.projectId 项目id
 * @param {String} params.updateTime 更新时间
 * @param {Number} params.page 页数
 * @param {Number} params.size 条数
 * @returns
 */
export const exportRankingList = (params) =>
  http({
    url: URL.EXPORT_RANKING_LIST,
    responseType: 'blob',
    timeout: 120000,
    method: 'get',
    params,
  });

/**
 * 自定义分值调整
 * @param {String} data.id 榜单id
 * @param {Number} data.customType 自定义类型 0 加 1 减
 * @param {String} data.customScore 自定义积分值
 * @returns
 */
export const adjustmentCustomScore = (data) => fetchApi(URL.ADJUSTMENT_CUSTOM_SCORE, data, 'post', 'data');

/**
 * 获取榜单周期列表
 * @returns
 */
export const getRankingPeriodList = (page = 1) => fetchApi(URL.GET_RANKING_PERIOD_LIST, {page, size: 52});
