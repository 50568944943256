// emoji表情
const emojiFace =
  /(\ud83c[\udc00-\udfff])|(\ud83d[\udc00-\udfff])|(\ud83e[\udc00-\udfff])|[\u2100-\u32ff]|[\u0030-\u007f][\u20d0-\u20ff]|[\u0080-\u00ff]/g;

// 禁止输入特殊字符和emoji表情公共方法
const forbiddenSpecialCharsEmoji = (value) => {
  value = value.replace(
    /[`~!@#$%^&*()_\-+=<>?:"{}|,./;"·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、" "～]/g,
    ""
  );
  value = forbidEmoji(value);
  return value.trim();
};

// 禁止输入emoji
const forbidEmoji = (value) => {
  if (value.target) {
    value.target.value = value.target.value.replace(emojiFace, "");
    return value.target.value;
  } else {
    value = value.replace(emojiFace, "");
    return value;
  }
};

// 数字键盘校验
export const validNumKeyBoard = (value) => {
  if (value.target.value == 0) {
    return ''
  }
  value.target.value = forbiddenSpecialCharsEmoji(value.target.value);
  value.target.value = value.target.value.replace(/[^0-9]/g, "");
  return value.target.value;
};
// 只允许输入字母数字
const validAllowCharNum = (value) => {
  value.target.value = value.target.value.replace(/[^a-zA-Z0-9]/g, "");
  return value.target.value;
};
// 姓名校验
const nameVerify = (value) => {
  value.target.value = forbiddenSpecialCharsEmoji(value.target.value);
  value.target.value = value.target.value.replace(/[0-9]/g, "");
  return value.target.value;
};

// 金额过滤
export const moneyFilter = (val, max, min) => {
  console.log(val)
  if (val !== '' && val.substring(0, 1) === '.') {
      val = '';
  }
  val = val.replace(/^0*(0\.|[1-9])/, '$1');//解决 粘贴不生效
  val = val.replace(/[^\d.]/g, "");  //清除“数字”和“.”以外的字符
  val = val.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
  val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
  val = val.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');//只能输入两个小数
  if (val.indexOf(".") < 0 && val !== "") {
      if (val.substr(0, 1) === '0' && val.length === 2) {
          val = val.substr(1, val.length);
      }
  }
  if (max || max === 0) {
      const maxNum = typeof max === 'string' ? Number(max) : max
      if ((maxNum || maxNum === 0) && Number(val) > maxNum) val = maxNum.toString();
  }
  if (min) {
      const minNum = typeof min === 'string' ? Number(min) : min;
      if (minNum && Number(val) < minNum) val = minNum.toString();
  }

  return val;
}

export default {
  validNumKeyBoard,
  nameVerify,
  validAllowCharNum,
  forbiddenSpecialCharsEmoji,
  forbidEmoji,
  moneyFilter
};
